import HttpService from '@/services/api/http.service';
import {DOC_FILES_GET} from '@/configs/endPoints';
import DocumentFile from '@/common/models/document-file';

export default class DocumentApiService {
  static async getFiles(id, signal) {
    const response = await HttpService.post(DOC_FILES_GET, {
      doc_id: id,
    }, signal);

    return response.items.map(item => new DocumentFile(item));
  }
}
