import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import auth from '@/store/modules/auth';
import commissions from '@/store/modules/commissions';
import controls from '@/store/modules/controls';
import table from "@/store/modules/table";
import autocorrect from "@/store/modules/autocorrect";
import doc_counters from "@/store/modules/doc_counters";
import roles from "@/store/modules/roles";
import system from "@/store/modules/system";
import sign from "@/store/modules/sign";
import lists from "@/store/modules/lists";
import actions from "@/store/modules/actions";
import row from "@/store/modules/row";
import mask from "@/store/modules/mask";
import versionControls from "@/store/modules/versionControls";
import splitSizes from "@/store/modules/splitSizes";
import preview from "@/store/modules/preview";
import afterRunRoutePoint from "@/store/modules/afterRunRoutePoint";
import user from '@/store/modules/user';
import server from '@/store/modules/server';
import previewFiles from '@/store/modules/previewFiles';

Vue.use(Vuex);

const ALPHA_DOC_VUEX_STORAGE = 'alpha-doc-vuex-obfuscate';
const ls = new SecureLS({
  isCompression: false,
  encodingType: 'aes',
  encryptionSecret: '3jh38353hiiw8o4hh333'
});
const vuexLocal = createPersistedState({
  paths: ['auth', 'user', 'server', 'roles', 'table', 'system', 'autocorrect', 'splitSizes'],
  key: ALPHA_DOC_VUEX_STORAGE,
  overwrite: false,
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});

export default new Vuex.Store({
  plugins: [vuexLocal],
  actions: {
    initPersistedStore(ctx) {
      window.addEventListener('storage', function ({key}) {
        if (key === ALPHA_DOC_VUEX_STORAGE && ls.get(ALPHA_DOC_VUEX_STORAGE)) {
          const stateNew = JSON.parse(ls.get(ALPHA_DOC_VUEX_STORAGE));
          if (ctx.state.auth.accessToken !== stateNew.auth.accessToken) {
            for (const stateNewKey in stateNew) {
              if (stateNewKey === 'table') {
                continue;
              }
              Vue.set(ctx.state, stateNewKey, stateNew[stateNewKey]);
            }
          }
          if (ctx.state.auth.refreshToken !== stateNew.auth.refreshToken) {
            Vue.set(ctx.state.auth, 'refreshToken', stateNew.auth.refreshToken);
          }
        }
      });
    },
    /*
      TODO: Очистка некоторых модулей приводит к повторной отправке запросов,
        а так как модуль авторизации к тому времени будет уже очищен, то будет получена 403.
    */
    cleanAllStoresAction(ctx) {
      ctx.commit('auth/clear');
      ctx.commit('user/clear');
      ctx.commit('server/clear');
      ctx.commit('cleanPreviewMutations');
      ctx.commit('clearCommissionsMutations');
      ctx.commit('clearControlsMutations');
      ctx.commit('autocorrectCleanMutations');
      ctx.commit('clearInterfaceElementsMutations');
      ctx.commit('clearSystemMutation');
      ctx.commit('clearSignBlockMutation');

      ctx.dispatch('clearListsAction');
      ctx.dispatch('clearRowAction');
      ctx.dispatch('tableClearAllAction');
      ctx.dispatch('clearAfterRunRoutePointAction');

      ls.remove(ALPHA_DOC_VUEX_STORAGE);
    }
  },
  mutations: {},
  state: {},
  getters: {},
  modules: {
    auth,
    user,
    server,
    previewFiles,
    versionControls,
    row,
    actions,
    commissions,
    controls,
    table,
    autocorrect,
    doc_counters,
    roles,
    system,
    sign,
    lists,
    mask,
    splitSizes,
    preview,
    afterRunRoutePoint,
  },
});
