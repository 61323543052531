<template>
  <ul class="dropdown-list-ui" ref="list">
    <dropdown-item-ui
      v-for="(option, index) in options"
      :key="option.code"
      type="list"
      :active="index === active"
      :checked="option.checked"
      :disabled="option.disabled"
      @click="$emit('select', option)"
      @mouseenter="active = index"
      @mouseleave="active = null"
    >
      {{ option.label }}
    </dropdown-item-ui>
  </ul>
</template>

<script>
import {defineComponent} from 'vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import {keys} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'DropdownListUi',
  components: {DropdownItemUi},
  props: {
    options: {
      type: Array,
      required: true,
      validator: keys('label', 'code'),
    },
  },
  emits: ['select'],
  data() {
    return {
      active: null,
    };
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    onKeydown(event) {
      switch (event.key) {
        case 'ArrowUp':
          this.onUp(event);
          break;
        case 'ArrowDown':
          this.onDown(event);
          break;
        case 'Enter':
          this.onEnter(event);
          break;
      }
    },
    onUp(event) {
      event.preventDefault();

      if (this.active === null) {
        this.setActive(0);
        return;
      }

      if (this.active > 0) {
        this.setActive(this.active - 1);
      } else {
        this.setActive(this.options.length - 1);
      }
    },
    onDown(event) {
      event.preventDefault();

      if (this.active === null) {
        this.setActive(0);
        return;
      }

      if (this.active < this.options.length - 1) {
        this.setActive(this.active + 1);
      } else {
        this.setActive(0);
      }
    },
    onEnter(event) {
      event.preventDefault();

      if (this.active !== null) {
        this.$emit('select', this.options[this.active]);
      }
    },
    setActive(index) {
      this.active = index;

      if (typeof index === 'number') {
        this.$refs.list?.children[index].scrollIntoView({behavior: 'smooth', block: 'nearest'});
      }
    },
  },
});
</script>

<style scoped lang="scss">
.dropdown-list-ui {
  overflow-y: auto;
  max-height: 270px;
}
</style>
