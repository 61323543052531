import HttpService from '@/services/api/http.service';
import {
  SYSTEM_FILE_ADD_VIEW,
  SYSTEM_FILE_ADD_WITH_CONTENT,
  SYSTEM_FILE_ADD_WITH_EDITS,
  SYSTEM_FILE_EDITION_GET,
  SYSTEM_FILE_META_GET,
} from '@/configs/endPoints';
import {FILE_ATTRIBUTES, FILE_GET_META_DELAY, FILE_GET_META_MAX_ATTEMPTS} from '@/services/api/file/file-api.consts';
import {delay} from '@/common/utils/promise';

export default class FileApiService {
  static async upload(request) {
    const {file_edition} = await HttpService.post(SYSTEM_FILE_EDITION_GET, {
      parent_table_id: request.parentTableId,
      parent_id: request.parentId,
      name: request.file.name,
    });

    const {file_id} = await HttpService.post(SYSTEM_FILE_ADD_WITH_CONTENT, {
      parent_table_id: request.parentTableId,
      parent_id: request.parentId,
      file_name: request.file.name,
      file_size: request.file.size,
      file_content: request.file.content,
      file_edition,
      comment: request.file.comment || undefined,
      attributes: FILE_ATTRIBUTES,
    });

    return file_id;
  }

  static async edit(file, pages, annotations) {
    const edits = Object.entries(annotations).map(([page, svg]) => ({
      page_url: pages[page - 1].url,
      page_edits_svg: svg,
    }));

    return await HttpService.post(SYSTEM_FILE_ADD_WITH_EDITS, {
      parent_table_id: file.parentTableId,
      parent_id: file.parentId,
      file_edits: edits,
      file_name: file.nameWithExtension,
      comment: file.comment,
      attributes: FILE_ATTRIBUTES,
    });
  }

  static async getPagesCount(id, hash, signal, attempt = 1) {
    try {
      const {count_pages} = await HttpService.post(SYSTEM_FILE_META_GET, {
        file_hash: hash,
      }, signal);

      return count_pages;
    } catch (error) {
      if (error.error_code !== 1001 || attempt >= FILE_GET_META_MAX_ATTEMPTS) {
        throw error;
      }

      await delay(FILE_GET_META_DELAY);
      return await FileApiService.getPagesCount(id, hash, signal, attempt + 1);
    }
  }

  /* TODO: Всегда игнорировать ошибку, либо вынести обработку из сервиса */
  static async addView(id, signal, skipError = true) {
    try {
      return await HttpService.post(SYSTEM_FILE_ADD_VIEW, {
        file_ids: [id],
      }, signal);
    } catch (error) {
      if (skipError) {
        return null;
      } else {
        throw error;
      }
    }
  }
}
