<template>
  <modal-ui ref="modal" :size="sizeModal" prevent-closure @shown="onShown">
    <template #body>
      <div class="master-modal-container" :class="{
                'master-modal-container__menu--small': smallLeftMenu,
                'master-modal-container__menu--hide': hideLeftMenu,
             }">
        <div class="master-modal-container__menu" v-show="!hideLeftMenu">
          <items-step-master :steps="steps"
                             :cursor-step="cursorStep"
                             :disabled-motion="disabledMotion"
                             :form-data="formData"
                             v-model="step"/>
        </div>
        <div>
          <header-master :classes-header="classesHeader"
                         :title-modal="titleModal"/>
          <component
            v-if="step?.component"
            :is="step.component"
            class="master-modal-container__body"
            v-model="formData"
            :additional="additional"
            :classesHeader="classesHeader"
            :titleModal="titleModal"
            ref="step"
            @hideModal="hide"
            @showLoader="showLoader"
            @hideLoader="hideLoader"
            @disabledMotion="disabledMotion = true"
            @enabledMotion="disabledMotion = false"
          />
          <footer v-if="step?.end" class="master-modal-container__footer" :class="classesFooter">
            <close-button
              v-if="showInEndClose"
              @click="hide" class="mr-2"
            />
            <add-button-legacy @click="ready">
              <template #icon>
                <div/>
              </template>
              <template #title>Готово</template>
            </add-button-legacy>
          </footer>
          <footer v-else class="master-modal-container__footer" :class="classesFooter">
            <close-button v-if="!disabledStep" @click="showAttentionBeforeClose" class="mr-2"/>
            <back-button v-if="!isFirstStep && !disabledMotion && !disabledStep" @click="back" class="mr-2"/>
            <add-button-legacy :disabled="disabledMotion" @click="next" v-if="!isLastStep">
              <template #icon>
                <div/>
              </template>
              <template #title>Далее</template>
            </add-button-legacy>
          </footer>
        </div>
      </div>

      <attention-modal :message="attentionMessage" @continueClosing="hide" ref="attentionModal"/>
    </template>
  </modal-ui>
</template>

<script>
import CloseButton from "@/components/buttons/CloseButton";
import AddButtonLegacy from "@/components/buttons/AddButtonLegacy.vue";
import BackButton from "@/components/buttons/BackButton";
import ItemsStepMaster from "@/components/master/step/ItemsStepMaster";
import AttentionModal from "@/components/modals/AttentionModal";
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';
import HeaderMaster from "@/components/master/HeaderMaster.vue";

export default {
  name: "MasterModal",
  mixins: [modal],
  data() {
    return {
      step: null,
      changed: false,
      disabledMotion: false,
      attentionMessage: '',
      cursorStep: 0,
      cursorStepName: '',
      formData: {},
    }
  },
  props: {
    master: {
      type: Object,
      required: true,
    }
  },
  components: {HeaderMaster, ModalUi, AttentionModal, ItemsStepMaster, BackButton, AddButtonLegacy, CloseButton},
  computed: {
    smallLeftMenu() {
      return this.getFieldPropertyFn(this.step, 'smallLeftMenu', false)
    },
    hideLeftMenu() {
      return this.getFieldPropertyFn(this.step, 'hideLeftMenu', false)
    },
    titleModal() {
      return this.getFieldPropertyFn(this.step, 'titleModal', this.master.title)
    },
    additional() {
      return this.getFieldPropertyFn(this.step, 'additional')
    },
    sizeModal() {
      return this.getFieldPropertyFn(this.step, 'sizeModal', 'm')
    },
    showInEndClose() {
      return this.getFieldPropertyFn(this.step, 'showInEndClose', false)
    },
    classesHeader() {
      return this.getFieldPropertyFn(this.step, 'classesHeader')
    },
    classesFooter() {
      return this.getFieldPropertyFn(this.step, 'classesFooter')
    },
    disabledStep() {
      if (!this.step?.disabled) {
        return false;
      }

      return this.step.disabled(this);
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === (this.steps.length - 1);
    },
    currentStep() {
      return this.steps.findIndex(step => step.title === this.step?.title) || 0;
    },
    steps() {
      return this.master.steps(this).filter(step => {
        if (step.hide === undefined) {
          return true;
        }

        if (typeof step.hide === "function") {
          return !step.hide(this);
        }

        return step.hide;
      })
    },
    stepsLength() {
      return this.steps.length;
    },
  },
  methods: {
    getFieldPropertyFn(step, prop, def = undefined) {
      let propVal = step && step[prop] !== undefined ? step[prop] : undefined;
      if (propVal) {
        if (typeof propVal === "function") {
          propVal = propVal(this);
        }
      }
      return propVal !== undefined ? propVal : def;
    },
    showAttentionBeforeClose() {
      if (!this.changed) {
        this.hide();
        return;
      }
      this.attentionMessage = this.master.attentionMessageBeforeClose;
      this.$refs.attentionModal.show();
    },
    onShown() {
      this.formData = {
        ...JSON.parse(JSON.stringify(this.master.formData))
      };
      this.disabledMotion = false;
      this.cursorStep = 0;
      this.cursorStepName = this.steps[this.cursorStep].title;
      this.step = this.steps[this.cursorStep] ?? null;
      this.changed = false
      setTimeout(() => this.changed = false, 500);
    },
    back() {
      const index = this.currentStep - 1;

      this.step = this.steps[index] ?? null;
    },
    ready() {
      this.$refs.step.ready();
    },
    next() {
      if (this.$refs.step.validate()) {
        const index = this.currentStep + 1;
        if (index > this.cursorStep) {
          this.cursorStep = index;
          this.cursorStepName = this.steps[this.cursorStep].title;
        }
        this.step = this.steps[index] ?? null;
      }
    },
  },
  watch: {
    stepsLength: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const index = this.steps.findIndex(step => step.title === this.cursorStepName);

          this.cursorStep = index > 0 ? index : this.cursorStep + index;
        }
      },
      deep: true,
      immediate: true,
    },
    formData: {
      handler() {
        this.changed = true;
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
