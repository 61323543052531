<template>
  <div class="loader-ui" :class="[`_position-${position}`, `_color-${color}`]">
    <spinner-ui v-if="!hiddenSpinner" size="l"></spinner-ui>
    <span v-if="text" class="text">{{ text }}</span>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'LoaderUi',
  components: {
    SpinnerUi,
  },
  props: {
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'white'),
    },
    text: String,
    position: {
      type: String,
      default: 'absolute',
      validator: only('absolute', 'static'),
    },
    hiddenSpinner: { // TODO: Отказаться
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.loader-ui {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &._position-static {
    height: 100%;
  }

  &._position-absolute {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }

  &._color-gray {
    background-color: var(--color-loader);
    backdrop-filter: blur(4px);
  }

  &._color-white {
    background-color: var(--color-white);
  }
}

.spinner-ui {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.text {
  color: var(--color-gray-600);
  text-align: center;
}
</style>
