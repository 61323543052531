import store from '@/store';
import axios from 'axios';
import {IgnorableAxiosException} from '@/services/exception/ignorableAxiosException';
import {IgnorableApiException} from '@/services/exception/ignorableApiException';

const instance = axios.create({
  baseURL: 'https://api.alpha-doc.ru/v1.1/',
  headers: {'Content-Type': 'text/plain'},
});

export default class HttpService {
  static async post(url, request = {}, signal, mapData = true) {
    const accessToken = store.state.auth.accessToken;

    const modifiedRequest = {
      ...request,
      token: accessToken,
    };

    const response = await instance.post(url, modifiedRequest, {signal}).catch(error => {
      // TODO: Убрать игнорирование ошибки, после того как очистка стора перестанет приводить к отправке запросов
      if (error.response?.status === 403) {
        throw new IgnorableAxiosException(error);
      }
      throw error;
    });

    const data = response.data;

    if (!data || typeof data.error !== 'boolean') {
      throw {
        error: true,
        error_code: 500,
        error_message: 'Ошибка сервера',
      };
    }

    if (!data.error) {
      return mapData ? data.data : data;
    }

    if (data.error_code !== 401) {
      throw data;
    }

    if (accessToken === store.state.auth.accessToken) {
      try {
        await store.dispatch('auth/refresh');
      } catch (error) {
        throw new IgnorableApiException(error);
      }
    }

    return HttpService.post(url, request, signal, mapData);
  }
}
