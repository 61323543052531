<template>
  <button class="button-ui" :class="`_${color}`" v-bind="$attrs" v-on="$listeners">
    <slot/>
  </button>
</template>

<script>
import {defineComponent} from 'vue';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: "ButtonUi",
  props: {
    color: {
      type: String,
      default: 'yellow',
      validator: only('yellow', 'outline')
    },
  },
});
</script>

<style scoped lang="scss">
.button-ui {
  padding: 0 8px;
  height: 24px;

  display: flex;
  align-items: center;

  border-radius: 4px;
  font-weight: var(--font-weight-bold);

  transition: color var(--transition-fast), background-color var(--transition-fast), border-color var(--transition-fast);

  font-size: var(--font-size);
  line-height: var(--line-height);

  &._outline {
    padding: 3px 7px;

    color: var(--color-gray-500);
    border: var(--border-gray-500);

    &:hover {
      color: var(--color-gray-600);
      border: var(--border-gray-600);

      .icon {
        color: var(--color-gray-600); /* TODO: Отказаться от перекрытия стилей */
        fill: var(--color-gray-600);
      }
    }

    .icon {
      color: var(--color-gray-500); /* TODO: Отказаться от перекрытия стилей */
      fill: var(--color-gray-500);
    }
  }
}

.icon {
  /* TODO: Отказаться от перекрытия стилей */
  display: block;
  width: auto;
  transition: fill var(--transition-fast), color var(--transition-fast), transform var(--transition-fast);
}
</style>
