import {LOAD_IMAGE_ATTEMPTS_COUNT, RELOAD_IMAGE_DELAY} from '@/common/consts/preview.consts';
import {delay} from '@/common/utils/promise';

export default class StorageApiService {
  static getImage(url) {
    return new Promise((resolve, reject) => {
      let original = false;
      let attempt = 1;

      const image = new Image();

      image.addEventListener('load', () => {
        if (original) {
          resolve()
        } else {
          original = true;
          image.src = url;
        }
      });

      image.addEventListener('error', async error => {
        if (attempt >= LOAD_IMAGE_ATTEMPTS_COUNT) {
          reject(error);
        } else {
          original = false;
          attempt++;
          await delay(RELOAD_IMAGE_DELAY);
          image.src = StorageApiService.addTimestamp(url);
        }
      });

      image.src = url;
    })
  }

  static addTimestamp(url) {
    return `${url}?t=${Date.now()}`;
  }
}
