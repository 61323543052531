<template>
  <preview-block title="Дополнительная информация" :loading="loading">
    <tabs-ui>
      <tab-ui v-if="showClientsTab" active>
        <template #title>
          Контрагенты
          <badge-ui class="ml-1" :value="docContractorsCountGetter"></badge-ui>
        </template>

        <uploader-block-document-preview class="mt-4"
                                         :end-point="DOCS_CLIENTS_GET"
                                         :doc-id="doc_id"
                                         :component="() => import('@/components/doc/contractors/ContractorRow')">
          <template v-slot:list-is-empty>Список контрагентов пуст</template>
        </uploader-block-document-preview>
      </tab-ui>

      <tab-ui v-if="showDocumentsViewTab" :active="!showClientsTab">
        <template #title>
          Просмотры
          <badge-ui class="ml-1" :value="docViewsCountGetter"></badge-ui>
        </template>

        <uploader-block-document-preview class="mt-4"
                                         :end-point="DOCS_VIEWS_ALL"
                                         :doc-id="doc_id"
                                         :component="() => import('@/components/doc/preview/rows/ViewRowDocumentPreview')">
          <template v-slot:list-is-empty>Список просмотров пуст</template>
        </uploader-block-document-preview>
      </tab-ui>

      <tab-ui :active="!showClientsTab && !showDocumentsViewTab">
        <template #title>
          Документы
          <badge-ui class="ml-1" :value="documentsLinksCountGetter"></badge-ui>
        </template>

        <uploader-block-document-preview class="mt-4"
                                         :end-point="DOC_LINKS_GET"
                                         :doc-id="doc_id"
                                         :component="() => import('@/components/table/grouped/DocumentsLinks')">
          <template v-slot:list-is-empty>Список связанных документов пуст</template>
        </uploader-block-document-preview>
      </tab-ui>
    </tabs-ui>
  </preview-block>
</template>

<script>
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import UploaderBlockDocumentPreview from '@/components/doc/preview/UploaderBlockDocumentPreview';
import BadgeUi from '@/components/ui/BadgeUi.vue';
import VuexAdapter from '@/services/vuexAdapter';
import {DOC_LINKS_GET, DOCS_CLIENTS_GET, DOCS_VIEWS_ALL} from '@/configs/endPoints';
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__VIEWS} from '@/configs/events';
import TabsUi from '@/components/ui/TabsUi';
import TabUi from '@/components/ui/TabUi';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';

export default defineComponent({
  name: 'AdditionalInfoDocumentPreview',
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreviewBlock,
    TabsUi,
    TabUi,
    BadgeUi,
    UploaderBlockDocumentPreview,
  },
  data() {
    return {
      DOCS_CLIENTS_GET,
      DOCS_VIEWS_ALL,
      DOC_LINKS_GET,
    };
  },
  computed: {
    ...mapGetters(['accessToEvent']),
    ...mapGetters({
      docViewsCountGetter: VuexAdapter.getNameCountGetter(DOCS_VIEWS_ALL),
      documentsLinksCountGetter: VuexAdapter.getNameCountGetter(DOC_LINKS_GET),
      docContractorsCountGetter: VuexAdapter.getNameCountGetter(DOCS_CLIENTS_GET),
    }),
    showDocumentsViewTab() {
      return this.accessToEvent(DOCUMENTS__VIEWS);
    },
    showClientsTab() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
  },
});
</script>
