import {DateTime} from 'luxon';
import axios from 'axios';
import FileApiService from '@/services/api/file/file-api.service';
import {saveFile} from '@/common/utils/save-file';
import store from '@/store';
import Revision from '@/common/models/revision';

export default class DocumentFile {
  constructor(data) {
    this.parentTableId = data['PARENT_TABLE_ID'];
    this.parentId = data['PARENT_ID'];
    this.id = data['F_ID'];
    this.hash = data['Файл'];
    this.nameWithExtension = data['Название'];
    this.comment = data['Примечание'];
    this.user = data['Пользователь'];
    this.signCount = data['ЭП'];
    this.revision = new Revision(data['Редакция']);
    this.isLast = this._getIsLast(data['Индикатор']);
    this.createdDate = this._getCreatedDate(data['Создан']);
    this.name = this._getName(this.nameWithExtension);
    this.extension = this._getExtension(this.nameWithExtension);
    this.downloadName = this._getDownloadName(data['DOC_ID'], this.revision.value, this.nameWithExtension);
    this.icon = this._getIcon(this.extension);
  }

  async download() {
    let url = `${store.state.server.storageUrl}${this.hash}.${this.extension}`;

    try {
      await axios.get(url);
      saveFile(url, this.downloadName);
    } catch (error) {
      // TODO: Уточнить сценарий и вынести логику в сервис
      await FileApiService.addView(this.id, undefined, false);
      setTimeout(() => this.download(), 1000);
    }
  }

  _getIsLast(indicator) {
    return indicator > 0;
  }

  _getCreatedDate(date) {
    return new DateTime.fromSQL(date).toFormat('dd.MM.yyyy, HH:mm');
  }

  _getName(nameWithExtension) {
    return nameWithExtension.split('.').slice(0, -1).join('.');
  }

  _getExtension(nameWithExtension) {
    return nameWithExtension.split('.').pop().toLowerCase();
  }

  _getDownloadName(documentId, revision, nameWithExtension) {
    return `${documentId}_${revision}_${nameWithExtension}`;
  }

  _getIcon(extension) {
    switch (extension) {
      case 'doc':
      case 'docx':
      case 'docm':
        return () => import('@/assets/svg/files/doc.svg?component');
      case 'xls':
      case 'xlsm':
      case 'xlsx':
      case 'xltx':
      case 'xlsb':
      case 'xlam':
        return () => import('@/assets/svg/files/xls.svg?component');
      case 'jpg':
      case 'jpeg':
        return () => import('@/assets/svg/files/jpg.svg?component');
      case 'ods':
        return () => import('@/assets/svg/files/ods.svg?component');
      case 'odt':
        return () => import('@/assets/svg/files/odt.svg?component');
      case 'pdf':
        return () => import('@/assets/svg/files/pdf.svg?component');
      case 'png':
        return () => import('@/assets/svg/files/png.svg?component');
      case 'rtf':
        return () => import('@/assets/svg/files/rtf.svg?component');
      default:
        return () => import('@/assets/svg/files/unknown.svg?component');
    }
  }
}
