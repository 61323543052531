<template>
  <div class="file-preview-edit">
    <edit-file-modal
      ref="annotationModal"
      :file="file"
      :pages="pages"
      :state="state"
      :sending="isLoading"
      :initial="initial"
      @fetch="$emit('fetch', $event)"
      @retry="$emit('retry', $event)"
      @change="change"
      @apply="apply"
      @close="close"
    ></edit-file-modal>

    <edit-file-confirm-modal
      ref="confirmModal"
      :file-name="file.nameWithExtension"
      @cancel="cancel"
      @discard="discard"
      @save="save"
    ></edit-file-confirm-modal>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import EditFileModal from '@/components/modals/File/EditFileModal.vue';
import EditFileConfirmModal from '@/components/modals/File/EditFileConfirmModal.vue';
import {mapActions} from 'vuex';
import {DOC_FILES_GET} from '@/configs/endPoints';
import {ERROR_NOTIFY_TYPE} from '@/configs/notifyTypes';
import FileApiService from '@/services/api/file/file-api.service';
import DocumentFile from '@/common/models/document-file';

export default defineComponent({
  name: 'FilePreviewEdit',
  components: {
    EditFileModal,
    EditFileConfirmModal,
  },
  props: {
    file: {
      type: DocumentFile,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    initial: {
      type: Number,
      required: true,
    },
  },
  emits: ['fetch', 'retry'],
  data() {
    return {
      isDirty: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(['refreshPartlyPreviewAction']),
    show() {
      this.$refs.annotationModal.show();
    },
    hide() {
      this.$refs.annotationModal.hide();
      this.isDirty = false;
      this.isLoading = false;
    },
    change(isDirty) {
      this.isDirty = isDirty;
    },
    cancel() {
      this.$refs.confirmModal.hide();
    },
    discard() {
      this.$refs.confirmModal.hide();
      this.hide();
    },
    save() {
      this.$refs.confirmModal.hide();
      const annotations = this.$refs.annotationModal.getAnnotations();
      this.apply(annotations);
    },
    close() {
      if (this.isLoading) {
        return;
      }

      if (this.isDirty) {
        this.$refs.confirmModal.show();
      } else {
        this.hide();
      }
    },
    async apply(annotations) {
      this.isLoading = true;

      try {
        await FileApiService.edit(this.file, this.pages, annotations);
        void this.refreshPartlyPreviewAction(DOC_FILES_GET);
        this.hide();
      } catch (error) {
        this.$notify({
          title: 'Ошибка при редактировании документа',
          type: ERROR_NOTIFY_TYPE,
          text: error?.error_message || 'Неизвестная ошибка',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.file-preview-edit {
  display: none;
}
</style>
