<template>
  <button class="button-icon-ui" :class="[`_size-${size}`, `_color-${color}`]" v-bind="$attrs" v-on="$listeners">
    <slot/>
  </button>
</template>

<script>
import {defineComponent} from 'vue';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'ButtonIconUi',
  props: {
    size: {
      type: String,
      default: 'm',
      validator: only('m', 'l'),
    },
    color: {
      type: String,
      default: 'transparent',
      validator: only('transparent', 'outline'),
    },
  },
});
</script>

<style scoped lang="scss">
.button-icon-ui {
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    :deep(svg),
    :deep(path) {
      fill: var(--color-gray-800);
    }
  }

  &._size-m {
    width: 24px;
    height: 24px;
  }

  &._size-l {
    width: 36px;
    height: 36px;
  }

  &._color-outline {
    border-radius: 8px;
    border: var(--border-gray-700);

    transition: border var(--transition-fast);

    &:not(:disabled):hover {
      border: var(--border-gray-800);
    }
  }
}

:deep(svg),
:deep(path) {
  fill: var(--color-gray-700);
  transition: fill var(--transition-fast);

  &:hover {
    fill: var(--color-gray-800);
  }
}
</style>
