<template>
  <button-ui color="outline" v-bind="$attrs" v-on="$listeners">
    <plus-icon class="icon" :class="{'icon_prefix-s': showTitle}"></plus-icon>
    <span v-if="showTitle">Добавить</span>
  </button-ui>
</template>

<script>
import {defineComponent} from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import PlusIcon from '@/assets/svg/plus.svg?component';

export default defineComponent({
  name: 'AddButton',
  components: {
    ButtonUi,
    PlusIcon,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
